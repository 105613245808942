import * as React from "react"
import { Box, Text, Flex } from "rebass/styled-components"
import { TimelineSVG } from "./TimelineSvg"
import { TimelineLegend } from "./TimelineLegend"
import { useEffect, useState } from "react"
// @ts-ignore
import useDimensions from "react-use-dimensions"
import styled, { css } from "styled-components"
import { theme } from "../../theme"

export const Timeline = () => {
  const [svgRef, svgDimensions] = useDimensions()
  const [ref, { y, width }, node] = useDimensions()
  const [anchorRef, anchorDimensions] = useDimensions()
  const [translateX, setTranslateX] = useState(0)
  const [scrollPadding, setScrollPadding] = useState(
    width > parseInt(theme.breakpoints[2]) ? 100 : 0
  )

  const moveRangeModifier = 150
  const moveRange = svgDimensions.width - width - moveRangeModifier

  const wrapperHeight = svgDimensions.height + moveRange * 2 + width
  const [scrollState, setScrollState] = useState<
    null | "SCROLLING" | "FINISHED"
  >(null)

  const handleScroll = () => {
    if (anchorDimensions && svgDimensions) {
      const isScrolling = y <= scrollPadding
      const finishedScrolling =
        -anchorDimensions?.y > svgDimensions.height + moveRange + scrollPadding
      if (finishedScrolling) {
        setScrollState("FINISHED")
      } else if (isScrolling) {
        setTranslateX(anchorDimensions.y || 0)
        setScrollState("SCROLLING")
      } else if (scrollState) {
        setScrollState(null)
      }
    }
  }

  useEffect(() => {
    handleScroll()
  })

  useEffect(() => {
    setScrollPadding(width >= parseInt(theme.breakpoints[2]) ? 100 : 0)
  }, [width])

  const heights = [
    wrapperHeight - Math.min(450, width),
    wrapperHeight - Math.min(400, width),
    wrapperHeight - Math.min(400, width),
    wrapperHeight - Math.min(250, width),
  ]

  return (
    <>
      <Anchor ref={anchorRef}></Anchor>
      <Wrapper
        ref={ref}
        flexDirection={"column"}
        minHeight={heights}
        sticky={scrollState != "FINISHED"}
        translateY={`${-translateX + scrollPadding - 20}px`}
        scrollPadding={scrollPadding}
      >
        <Flex justifyContent="center" alignItems={"center"} mx={"auto"}>
          <Text
            fontFamily="Gentona-Book"
            fontSize="15px"
            mb={[3, "70px"]}
            maxWidth={["100%", "100%", "650px"]}
          >
            Discovery of Tickborne Pathogens as Causes of Human Disease in the
            United States, 1900-Present
          </Text>
        </Flex>

        <TimelineBox
          height={"440px"}
          mb={[6, "60px"]}
          mx={["-20px", "-40px", "-60px", "-20px", "auto"]}
        >
          <Box style={{ position: `relative`, left: width / 2 + translateX }}>
            <TimelineSVG ref={svgRef} />
          </Box>
        </TimelineBox>

        <Box mb={"20px"}>
          <TimelineLegend />
        </Box>
        <Flex justifyContent="center" alignItems={"center"} mx={"auto"}>
          <Text fontFamily="Gentona-Medium" fontSize="15px" lineHeight="148.6%">
            <strong>Source:</strong> CDC. Adapted from Paddock CD, Lane RS,
            StaplesJE, Labruna MB. 2016. In: Mack A, Editor. Global health
            impacts of vector-borne diseases: workshop summary. National
            Academies Press. p. 221-257. spp. = species pluralis.
          </Text>
        </Flex>
      </Wrapper>
      <Anchor height={[`${moveRangeModifier}px`]}></Anchor>
    </>
  )
}

const stickyCss = css`
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
`
const Anchor = styled(Box)`
  ${stickyCss}
`

const Wrapper = styled(Flex)<{
  sticky: boolean
  translateY: string
  scrollPadding?: number
}>`
  -webkit-position: ${({ sticky }) => (sticky ? stickyCss : "relative")};
  position: ${({ sticky }) => (sticky ? stickyCss : "relative")};
  top: ${({ sticky, translateY, scrollPadding }) =>
    sticky ? `${scrollPadding}px` : translateY};
  max-width: 100%;

  pointer-events: none;
  > * {
    max-width: 650px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    top: ${({ sticky, translateY, scrollPadding }) =>
      sticky ? `${scrollPadding}px` : translateY};
  }
`

const TimelineBox = styled(Box)`
  position: relative;
  width: calc(100vw);
  max-width: calc(100vw);
  overflow: hidden;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: calc(100% + 40px);
  }

  @media screen and (min-width: 1330px) {
    margin-left: -90px;
    width: calc(100vw - 2 * 20px);
    max-width: 1290px;
  }

  svg {
    transition: 0.02s ease-out;
  }
`
