import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/common/Layout";
import SEO from "../components/seo";
import { Storymap } from "../components/storymap/Storymap";
import { Timeline } from "../components/storymap/Timeline";
import { CasesChart } from "../components/storymap/CasesChart";
import { Box, Button, Flex, Link, Text } from "rebass/styled-components";
import image1 from "../images/storymap/image 1.png";
import image1_1 from "../images/storymap/image 1-1.png";
import image2 from "../images/storymap/image 2.png";
import image3 from "../images/storymap/image 3.png";
import image4 from "../images/storymap/image 4.png";
import image5 from "../images/storymap/image 5.png";
import image6 from "../images/storymap/image 6.png";
import image7 from "../images/storymap/image 7.png";
import image7_1 from "../images/storymap/image 7_1.png";
import image8 from "../images/storymap/image 8.png";
import image9 from "../images/storymap/image 9.png";
import image10 from "../images/storymap/image 10.png";
import image11 from "../images/storymap/image 11.png";
import { WhatsGoingOnWhereILiveSmall } from "../components/storymap/WhatsGoingOnWhereILiveSmall";
import { StorymapImage } from "../components/storymap/StorymapImage";
import { SourceNote } from "../components/storymap/SourceNote";
import { routes } from "../page-data/routes";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => {
  // children here is the content generated from markdown below
  return <Layout>
      <SEO title="Geography, Ticks and You" />
      <Storymap content={children} />
    </Layout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1><a id="anchor-1">{`What are tickborne diseases?`}</a></h1>
    <p>{`When spending time outdoors, whether hiking, gardening, or walking your dog, you may encounter ticks – tiny, blood-sucking parasites that live in wooded, brushy, and tall grassy areas. Ticks can be vectors (carriers) of many diseases that affect humans, dogs, and other animals. Not all tick species spread disease. Of those that do, different species spread different diseases, and some spread more than one “co-infection”.`}</p>
    <StorymapImage src={image1} position={"left"} mdxType="StorymapImage" />
    <p>{`You may have heard of Lyme disease or even know someone who has been affected by it. Lyme disease is the most common
vector-borne disease in the United States, where the primary bacterium that causes it, `}<em parentName="p">{`Borrelia burgdorferi`}</em>{`, is
spread to humans through the bite of infected blacklegged ticks. Early symptoms of Lyme disease in humans can include a skin rash, fever, achiness, and fatigue. Timely diagnosis and treatment are important to prevent the infection from spreading to other parts of the body, and to reduce the risk of persistent symptoms after treatment. There is no human vaccine for Lyme disease.`}</p>
    <p>{`Other tickborne diseases in the US include babesiosis, ehrlichiosis, anaplasmosis, southern tick-associated rash illness, Rocky Mountain spotted fever, tickborne relapsing fever, tularemia, and Powassan virus. However, tickborne diseases are not just a US problem. They are found throughout the world, including Canada, Europe, Africa, and Asia.`}</p>
    <Box mdxType="Box"><SourceNote lineHeight={"1.2em"} mdxType="SourceNote">*Please consult the CDC Tick <Link href={"https://www.cdc.gov/ticks/index.html"} mdxType="Link">website</Link> for more information about the signs and symptoms of different tickborne diseases and what to do if you have been bitten by a tick.</SourceNote></Box>
    <StorymapImage src={image1_1} position={"left"} mdxType="StorymapImage" />
    <h1><a id="anchor-2">{`Why are tickborne diseases a public health concern?`}</a></h1>
    <p>{`Tickborne diseases are on the rise. More people are getting sick, ticks are spreading to new geographic locations,
and new tickborne pathogens and diseases are being discovered. Recently, scientists have been trying to understand
why bites from lone star ticks are causing some people to have severe `}<Link href="https://www.cdc.gov/ticks/alpha-gal/index.html" target="_blank" mdxType="Link"><strong parentName="p">{`allergic reactions to red
meat!`}</strong></Link></p>
    <Timeline mdxType="Timeline" />
    <p>{`We do not know the true burden of tickborne diseases, especially Lyme disease. There is significant underreporting
of cases due to the passive nature of the surveillance system, which relies on busy healthcare providers initiating the reporting of cases to public health agencies. In recent years, Massachusetts,
an endemic state for Lyme disease, has `}<Link href="https://www.nbcboston.com/news/local/state-health-leaders-dispute-cdcs-claim-of-drop-in-lyme-cases/1945332/" target="_blank" mdxType="Link"><strong parentName="p">{`stopped
spending the time and resources`}</strong></Link>{` required to report cases to the
US Centers for Disease Control and Prevention (CDC). This example underscores the fact that passive surveillance can produce misleading maps as well as gaps in knowledge, because we don’t know how much changes in case counts are a result of underreporting or other reasons, such as yearly fluctuations in tick populations or educational interventions.`}</p>
    <p>{`Using health insurance claims data, CDC has estimated that around `}<Link target="_blank" href="https://www.cdc.gov/lyme/stats/humancases.html" mdxType="Link"><strong parentName="p">{`476,000 Americans are diagnosed and treated`}</strong></Link>{` for
Lyme disease each year – a figure that is more than ten times the number of reported cases! The disease is estimated
to cost the US health care system between `}<Link href="https://www.jhsph.edu/news/news-releases/2015/lyme-disease-costs-more-than-one-billion-dollars-per-year-to-treat-study-finds.html" target="_blank" mdxType="Link"><strong parentName="p">{`$712 million and $1.3 billion`}</strong></Link>{` each year, but this cost
burden could be even higher if cases are underestimated. As estimated cases surpass most other infectious diseases,
federal
funding
for improved prevention, diagnosis, and treatment still `}<Link target="_blank" href="https://www.hhs.gov/sites/default/files/tbdwg-report-to-congress-2018.pdf" mdxType="Link"><strong parentName="p">{`lags other diseases`}</strong></Link>{`.`}</p>
    <CasesChart mdxType="CasesChart" />
    <p>{`Although surveillance data give us an incomplete picture of Lyme and other tickborne diseases – both in terms of their numbers and their burden to society – there is a lot that these data tell us. For example, we know that since national surveillance for Lyme disease began in the United States in 1991 (Canada in 2009), reported cases have increased steadily. We also know that although cases are reported year-round, they tend to peak in the late spring and summer months when nymphal ticks (immature ticks that are very small and hard to see on skin) are most active, and people spend more time outdoors. Most importantly, we know that geography matters. Your risk of getting Lyme disease varies depending on where you live or travel.`}</p>
    <WhatsGoingOnWhereILiveSmall mdxType="WhatsGoingOnWhereILiveSmall" />
    <h1>{`Why does geography matter for tickborne diseases?`}</h1>
    <p>{`Tickborne diseases have complex ecologies. Ecology simply means how living things are connected to each other and their physical surroundings. Geography matters in terms of where ticks and their hosts (the animals that they bite) are distributed, how environmental factors such as climate and land cover affect the distribution of both, and how people – the “you” in this story – encounter ticks. All of these are interrelated and affect disease risk in complex ways.`}</p>
    <p>{`Importantly, different tick species have different geographic distributions, and these distributions can expand over time. Where ticks live strongly depends on a range of factors like tick behavior, host availability, and environmental conditions. These factors in turn influence where cases of disease are reported.`}</p>
    <Box className="fullwidth" mdxType="Box">
  <Flex className="fullwidth" justifyContent="center" alignItems="center" flexWrap={["wrap", "wrap", "wrap", "nowrap"]} mdxType="Flex">
    <Flex width={[1, 1, 1, 1 / 2]} justifyContent="center" mb={[3, 3, 3, 0]} mr={[0, 0, 0, 3]} mdxType="Flex"><img src={image2} /></Flex>
    <Flex width={[1, 1, 1, 1 / 2]} justifyContent="center" mdxType="Flex"><img src={image3} /></Flex>
  </Flex>
  <SourceNote mdxType="SourceNote">
    <strong>Source:</strong> CDC
  </SourceNote>
    </Box>
    <p>{`Reported cases of Lyme disease in the US are concentrated in endemic areas in the Northeast, mid-Atlantic, upper
Midwest, and West Coast. However, the ticks that spread Lyme disease are `}<Link target="_blank" href="https://academic.oup.com/jme/article/53/2/349/2459744" mdxType="Link">{`
`}<strong parentName="p">{`expanding their geographic range`}</strong>{`.`}</Link>{`
As a result, Lyme disease is also expanding geographically, both south into states like North Carolina and Tennessee
and north into Canada.`}</p>
    <Box className="fullwidth" mdxType="Box">
  <Flex className="fullwidth" justifyContent="center" alignItems="center" flexWrap={["wrap", "wrap", "wrap", "nowrap"]} mdxType="Flex">
    <Flex width={[1, 1, 1, 1 / 2]} justifyContent="center" mb={[3, 3, 3, 0]} mr={[0, 0, 0, 3]} mdxType="Flex"><img src={image4} /></Flex>
    <Flex width={[1, 1, 1, 1 / 2]} justifyContent="center" mdxType="Flex"><img src={image5} /></Flex>
  </Flex>
  <SourceNote mdxType="SourceNote">
    <strong>Source:</strong> CDC
  </SourceNote>
    </Box>
    <p>{`There is a lot of complexity regarding what is driving the geographic spread of ticks and tickborne diseases. This makes it difficult to predict for any given year how many cases there will be and where those cases will be.`}</p>
    <h1><a id="anchor-3">{`Why is the geography of tickborne diseases so complicated?`}</a></h1>
    <p>{`Ticks have a complex lifecycle that can take up to three years to complete. Most ticks, including the blacklegged tick, have four life stages: egg, larva, nymph, and adult. After hatching, ticks must take a several day-long blood meal at each stage to survive. Blacklegged ticks can feed from mammals, birds, and reptiles, but they require a new host at each stage. During their lifecycle, they have multiple opportunities to become infected, to infect their hosts, and to hitch a ride on their hosts to new areas. Many factors can influence their abundance and infection rate during this time, including landscape features, temperature, rainfall, and host availability. Additionally, these conditions can also determine how different tick species (and even the same species in different geographic areas) may behave in seeking out a host.`}</p>
    <Flex className="fullwidth" flexDirection="column" alignItems="center" pt={"20px"} mdxType="Flex">
  <img src={image6} style={{
        mixBlendMode: "multiply"
      }} />
  <SourceNote mdxType="SourceNote"><strong>Source:</strong> CDC</SourceNote>
    </Flex>
    <p>{`Predicting geographic variation in the risk of Lyme and other tickborne diseases is made more challenging by the
lack of systematic surveillance of tick populations. That is why scientists are so interested in studying what
determines variation in tick abundance and infection across space and time. Two major processes that have been
implicated in the geographic spread of tickborne diseases are `}<Link target="_blank" href="https://royalsocietypublishing.org/doi/10.1098/rstb.2016.0117?url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org&rfr_dat=cr_pub++0pubmed&" mdxType="Link"><strong parentName="p">{`land use change`}</strong></Link>{`, including forest
fragmentation, and `}<Link target="_blank" href="https://health2016.globalchange.gov/vectorborne-diseases" mdxType="Link"><strong parentName="p">{`climate
change`}</strong></Link>{`.`}</p>
    <h1><a id="anchor-4">{`What is forest fragmentation and how does it affect tickborne disease risk?`}</a></h1>
    <p>{`Forest fragmentation happens when large, continuous blocks of forest are divided into smaller patches to make space for roads, houses and other buildings, and agriculture. Land use changes over the past several decades have included reforestation of former farmland and forest fragmentation for suburban development.`}</p>
    <StorymapImage src={image7} position={"right"} mdxType="StorymapImage" />
    <p>{`Wooded and forested areas can support a diversity of animals, but many animals depend on the stable environmental
conditions (e.g., temperature, sunlight, and humidity) that undisturbed forest interiors provide. Fragmentation
exposes them to less stable “edge” environments and can reduce their numbers. This loss of diversity reduces the number of predators and competitors of the white-footed mouse, which is the most competent reservoir (or source) for `}<em parentName="p">{`Borrelia burgdorferi`}</em>{`, the Lyme disease-causing bacterium. White-footed mice thrive in fragmented forests, where they become the predominant host for ticks in their larval stage. Unlike some other host animals, white-footed mice don’t groom ticks from their bodies. Increasing mice populations can lead to an increased number of ticks and an increased number of infected ticks.`}</p>
    <StorymapImage src={image7_1} position={"left"} mdxType="StorymapImage" />
    <Flex mdxType="Flex"><SourceNote m={'-1.8em'} mdxType="SourceNote">Photo by Phil Myers, animaldiversity.org</SourceNote></Flex>
    <p>{`White-tailed deer also thrive in fragmented forests, especially the transitional area between forest and open, vegetated areas where they like to forage for food. Deer populations have increased over the years as populations of predator animals have been reduced by human activity, deer hunting has declined, and foraging area has expanded due to forest fragmentation. Although deer do not carry Lyme disease, because adult ticks primarily feed on deer, they play an important role in the tick lifecycle and are a determinant of tick abundance in subsequent generations. The movement of deer is also responsible for the spread of ticks into novel areas.`}</p>
    <StorymapImage src={image8} position={"right"} mdxType="StorymapImage" />
    <p>{`The ecological interactions taking place in fragmented forests are complicated and understudied, but it is important to know that forest fragmentation brings people into closer contact with areas where ticks may live.`}</p>
    <h1><a id="anchor-5">{`How does climate change affect tickborne disease risk?`}</a></h1>
    <p>{`Blacklegged ticks spend most of their lives in vegetation and leaf litter, where factors like temperature, precipitation, and humidity can affect their survival and host-seeking behavior. They generally don’t do well in extreme environments that are too hot, dry, or cold. But for ticks, their microclimate, or the climate of their very small surrounding area, is also important. Even in adverse conditions such as high temperature and low humidity, ticks can seek refuge in leaf litter where there is more shade and moisture.`}</p>
    <StorymapImage src={image9} position={"left"} mdxType="StorymapImage" />
    <p>{`Rising temperatures due to climate change are expected to increase the range of favorable habitats for blacklegged ticks. For example, Lyme disease is already becoming more common in parts of Canada that previously were too cold for tick populations to become established. Warmer winter and spring temperatures in the eastern US could also lead to an earlier start to nymphal tick activity and thus an earlier start to the Lyme disease season. Climate change may also be affecting the distribution of tick host species, which adds another layer of complexity to the future distribution of ticks.`}</p>
    <h1>{`If the ticks that spread Lyme disease have such a wide geographic distribution, why do most cases occur in just a few states?`}</h1>
    <p>{`There is a lot of uncertainty and active research about this question. Possible explanations involve geographic differences in host diversity, tick behavior, and human behavior.`}</p>
    <p>{`When ticks feed from a variety of available host species, their chances of becoming infected with `}<em parentName="p">{`Borrelia burgdorferi`}</em>{` are lower because only a few species, like the white-footed mouse, are competent reservoirs for the
bacterium. It also means that fewer infected ticks bite people. Geographic variation in the composition of host species could explain why rates of human disease are higher in some locations compared to others.`}</p>
    <Flex className="fullwidth" flexDirection="column" alignItems="center" pt={"20px"} mdxType="Flex">
  <img src={image10} style={{
        mixBlendMode: "multiply"
      }} />
  <SourceNote mdxType="SourceNote">Reported Cases of Lyme Disease – United States, 2018  <strong>Source:</strong> CDC
  </SourceNote>
    </Flex>
    <p>{`There could also be geographic differences in tick behavior. Research suggests that blacklegged ticks in the south selectively feed from lizards like skinks. These ticks tend to remain in the leaf litter when seeking hosts, while their northern counterparts tend to climb onto taller vegetation, increasing their chances of attaching to a passing human or other larger mammal. Differences in tick behavior could also be associated with climate. Warmer southern temperatures increase the risk of ticks drying out and dying, so ticks may prefer to seek hosts that are nearby in the leaf litter. There is a lot of uncertainty about the impact of climate change on tick behavior. Will rising temperatures in the mid-Atlantic cause ticks in this region to behave more like southern ticks?`}</p>
    <StorymapImage src={image11} position={"right"} mdxType="StorymapImage" />
    <p>{`Tickborne disease risk also depends on aspects of human behavior, including participation in activities that bring people into contact with ticks and adoption of self-protective measures. Geographic variation in human behavior could also help explain differences between the distribution of ticks and the incidence of human disease.`}</p>
    <h1><a id="anchor-6"></a>{`Where do we go from here?`}</h1>
    <p>{`Our mission is to harness and illustrate the power of geography in tickborne disease research. Our next step is to
form collaborative partnerships that will leverage resources and knowledge to close data gaps. We aim to expand our data resources across North America and eventually worldwide and ensure that these resources collectively represent a One Health, trans-disciplinary approach that considers the health of people, animals, and the environment.`}</p>
    <p>{`But this story ends with you. What can you do? Whether you are a researcher, clinician, public health practitioner,
have a connection to Lyme disease, or are simply curious about tickborne diseases, our website has resources for
you. Our `}<Link href="/explorer" mdxType="Link">{`Data Explorer`}</Link>{` allows you to visualize and download relevant data and maps.
Our `}<Link href="/news" mdxType="Link">{`News & Resources`}</Link>{` section links to information, research publications, and other
resources on tickborne diseases. We hope that you will spread the word about our website and help us spotlight the
relevance of geography to tickborne diseases. Most importantly, our call to action is for more research and awareness about the growing, but often overlooked, problem of tickborne diseases.`}</p>
    <h1>{`Explore More`}</h1>
    <p>{`Use our interactive Data Explorer to visualize tickborne disease-related maps and download the data for further exploration. Our innovative tool is designed to inspire research and scientific collaboration.`}</p>
    <Box width={1} mdxType="Box"><Link href={routes.explorer} mdxType="Link"><Button variant={"underline"} mdxType="Button">Go to Data Explorer</Button></Link></Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      