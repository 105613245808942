import { Box, Flex, Heading, Text } from "rebass/styled-components"
import { IComboBoxStyles, SearchBox } from "office-ui-fabric-react"
import * as React from "react"
import styled from "styled-components"
import { data } from "../homepage/data"
import { useEffect, useState } from "react"
import { CasesData } from "../homepage/Chart"
import { LocalDetails } from "../common/LocalDetails"
import { ContentWrapper } from "../ContentWrapper"
import Autocomplete from "react-autocomplete"
import { isMatchingInput, sortStates } from "../common/autocompleteHelper"
import { theme } from "../../theme"

// TODO duplicated from UserKPIInput
const customStyles: Partial<IComboBoxStyles> = {
  root: {
    maxWidth: "300px",
    backgroundColor: "transparent",
    borderWidth: 0,
  },
  container: { border: "none", borderWidth: 0 },
  input: {
    backgroundColor: "transparent",
    paddingLeft: 30,
    border: "none",
    borderWidth: 0,
  },
}

export function WhatsGoingOnWhereILiveSmall() {
  const [searchQuery, setSearchQuery] = useState("")
  const [value, setValue] = useState("")
  const [casesData, setCasesData] = useState<CasesData>([])
  const options = casesData.map(o => ({ label: o.search }))

  const selectedDataPoint = searchQuery
    ? casesData.find(d => d.search === searchQuery)
    : null

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e && e.target && e.target.value) || ""
    setValue(value)
  }

  const onSelect = (value: string) => {
    setValue(value)
    setSearchQuery(value)
  }

  useEffect(() => {
    fetch("/cases-minified.json").then(async res => {
      const casesData = await res.json()
      setCasesData(casesData)
    })
  }, [])

  return (
    <Box textAlign="left" className="fullwidth">
      <ContentWrapper maxWidth="650px">
        <div>
          <Heading fontSize={"32px"} fontFamily="Gentona-Book">
            {data.search.heading}
          </Heading>
          <Text mt={4} mb={4} fontSize={"17px"} fontFamily="Gentona-Light">
            {data.search.subheading}
          </Text>
        </div>

        <InputWrapper width={[1, "457px"]}>
          {/* TODO searchbox needed just to render the icon properly, fix that */}
          <SearchBox value={""} onChange={() => {}} onClear={() => {}} />
          <i
            data-icon-name="Search"
            aria-hidden="true"
            className="ms-SearchBox-icon icon-46"
          >
            
          </i>
          <Autocomplete
            getItemValue={item => item.label}
            items={options}
            sortItems={sortStates}
            shouldItemRender={isMatchingInput}
            renderItem={(item, isHighlighted) => (
              <Box
                p={2}
                style={{
                  background:
                    isHighlighted || item.label === value
                      ? "lightgray"
                      : "white",
                }}
              >
                {item.label}
              </Box>
            )}
            value={value}
            onChange={onChange}
            onSelect={onSelect}
          />
        </InputWrapper>
      </ContentWrapper>

      {searchQuery && selectedDataPoint && (
        <DetailsWrapper mt={[4]} mx={["-24px", "-48px", "-88px"]} bg={"white"}>
          <ContentWrapper maxWidth="900px">
            <LocalDetails
              selectedDataPoint={selectedDataPoint}
              variant={"storymap"}
            />
          </ContentWrapper>
        </DetailsWrapper>
      )}
    </Box>
  )
}

const DetailsWrapper = styled(Flex)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-left: -20px;
    width: min(calc(100vw - 80px), 1290px);
    .cases-chart {
      transform: translateY(80px);
    }
  }

  @media screen and (min-width: 1230px) {
    margin-left: calc(-50vw + 50% + 40px);
  }

  @media screen and (min-width: 1360px) {
    margin-left: -89px;
  }
`

const InputWrapper = styled(Box)`
  position: relative;
  input {
    background-color: transparent;
    border-left: none;
    border-top: none;
    border-right: none;
    padding-left: 30px;
    padding-bottom: 5px;
    width: 400px;
    max-width: calc(100vw - 40px);
  }

  input ~ div {
    margin-top: 10px;
    z-index: 10;
    height: fit-content;

    max-height: 300px !important;
  }
  input:focus {
    outline: none;
    border-bottom: 2px solid ${theme.colors.headingAccent} !important;
  }
  input:active {
    border-bottom: 2px solid ${theme.colors.headingAccent} !important;
  }

  i {
    position: absolute;
    top: 3px;
    left: 5px;
  }

  .ms-SearchBox {
    display: none;
  }

  .ms-ComboBox button {
    display: none;
  }

  .ms-ComboBox-container *::after {
    border-left: none;
    border-top: none;
    border-right: none;
  }
`
