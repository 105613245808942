import * as React from "react"
import { Flex } from "rebass/styled-components"
import { theme } from "../../theme"
// @ts-ignore
import useDimensions from "react-use-dimensions"

interface IStorymapImage {
  src: string
  position?: "left" | "right"
  style?: React.CSSProperties
}

export const StorymapImage = ({ src, position, style }: IStorymapImage) => {
  const left = position === "left" ? "-80px" : undefined
  const right = position === "right" ? "-100px" : undefined
  const [ref, { width }] = useDimensions()
  const isDesktop = width > parseInt(theme.breakpoints[2])
  const move = isDesktop ? { left, right } : {}

  return (
    <Flex
      className="fullwidth"
      flexDirection="column"
      alignItems="center"
      mb={0}
      ref={ref}
    >
      <img
        src={src}
        style={{ position: "relative", marginBottom: 0, ...style, ...move }}
      />
    </Flex>
  )
}
