import * as React from "react"
import { Text, TextProps } from "rebass/styled-components"

export const SourceNote = ({
  children,
  ...props
}: {
  children: React.ReactNode
} & TextProps) => (
  <Text
    fontFamily="Gentona-Medium"
    fontSize="14px"
    mt={"-10px"}
    maxWidth={650}
    margin={"auto"}
    {...props}
  >
    {children}
  </Text>
)
