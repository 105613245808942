import * as React from "react"
import { Text, Flex, Box } from "rebass/styled-components"
import styled from "styled-components"
import { Chart } from "../homepage/Chart"

const casesByYear = [
  { year: 2000, cases: 17730 },
  { year: 2001, cases: 17029 },
  { year: 2002, cases: 23763 },
  { year: 2003, cases: 21273 },
  { year: 2004, cases: 19804 },
  { year: 2005, cases: 23305 },
  { year: 2006, cases: 19931 },
  { year: 2007, cases: 27444 },
  { year: 2008, cases: 35198 },
  { year: 2009, cases: 38468 },
  { year: 2010, cases: 30158 },
  { year: 2011, cases: 33097 },
  { year: 2012, cases: 30831 },
  { year: 2013, cases: 36307 },
  { year: 2014, cases: 33461 },
  { year: 2015, cases: 38069 },
  { year: 2016, cases: 36429 },
  { year: 2017, cases: 42743 },
  { year: 2018, cases: 33666 },
  { year: 2019, cases: 34945 },
]

export const CasesChart = () => (
  <Flex flexDirection="column">
    <Text
      textAlign="left"
      fontFamily="Gentona-Medium"
      fontSize="15px"
      mb={[0, -2, -4]}
    >
      Reported Lyme Disease Cases, 2000-2019
    </Text>
    <Wrapper>
      <Chart label={"Cases"} data={casesByYear} />
    </Wrapper>
  </Flex>
)

const Wrapper = styled(Box)``
